<template id="">
  <div class="uk-margin-top">
    <h3>登録用URLの有効期限が切れています</h3>
    <p>お手数ですが再度、新規登録を行ってください</p>
    <button
      type="button"
      name="button"
      class="uk-button uk-button-secondary"
      @click="$router.push('/client-login')"
    >
      ログインページに戻る
    </button>
  </div>
</template>
<script type="text/javascript">
document.title = "有効期限切れ";
</script>
